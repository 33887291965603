/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHistory } from 'react-router';
import { Colors } from "../utils/Colors";
import inspection from "../assets/icons/inspection.svg";
import maintenance from "../assets/icons/maintenance.svg";
import consumables from "../assets/icons/consumables.svg";
import { ServiceItem } from "../components/SelectAPlan/ServiceItem";
import { handsOnMaintenanceServices, includedConsumablesServices, inspectionServices } from "../utils/services";
import { ScrollToTopButton } from "../components/common/ScrollToTopButton";
import back from '../assets/icons/close.svg';


const headerStyle = css`
    margin-top: 30px;
    display: flex;
    justify-content:space-between;
`
const backButtonSyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 33.44px;
    color: ${Colors.goodHouseHeading}
`

const subtitleStyle = css`
    margin-top: 14px;
    font-size: 14px;
    font-weight: 500;
    color: ${Colors.goodHouseHeading}
`

const imageStyle = css`
    height: 20px;
    width: 20px;
`
const serviceContainerStyle = css`
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
    color:#656566;

`
const serviceTitleStyle = css`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-left: 10px;
    `
const goToTopStyle = css`
     position: fixed;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      @media (min-width: 768px) {
        display: none!important;
    } 
    `

interface IRenderItemsProps {
    items: object[],
    icon: string,
    title: string
}

const RenderItems = (props: IRenderItemsProps) => {
    const { icon, items, title } = props
    return (

        <div>
            <div css={serviceContainerStyle}>
                <img src={icon} css={imageStyle} alt="inspection" />
                <span css={serviceTitleStyle}>{title}</span>
            </div>
            {items?.map((service: any) => <ServiceItem key={service.label} serviceName={service.label} />)}
        </div>
    )
}

export const AllServicesPlanPage = () => {
    const history = useHistory();

    return (
        <div>
            <div css={headerStyle}>
                <span css={backButtonSyle}>Plan services</span>
                <img src={back} alt="back" onClick={() => {
                    history.goBack();
                }} />


            </div>
            <h2 css={subtitleStyle}>All services</h2>

            <RenderItems items={inspectionServices} icon={inspection} title='Inspection' />
            <RenderItems items={handsOnMaintenanceServices} icon={maintenance} title='Hands on Maintenance' />
            <RenderItems items={includedConsumablesServices} icon={consumables} title='Included Consumables (free)' />

            <div css={goToTopStyle}>
                <ScrollToTopButton />
            </div>
        </div>
    );

};



