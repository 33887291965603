import { Global, css } from "@emotion/react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login";

import AppleLogin from "react-apple-login";
import { LoginForm } from "../forms/forms/LoginForm";
import { config } from "../config";
import Logo from "../assets/icons/greenhouse-logo.svg";
import GoogleLogo from "../assets/icons/google-logo.svg";
import { http } from "../api/http";
import { useLocalState } from "use-enhanced-state/dist/types";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo
} from "react-facebook-login";
import { setTokenSourceMapRange } from "typescript";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [_, setToken] = useLocalState("token", undefined);
  const history = useHistory();

  return (
    <div className="login-form">
      <Global styles={styles} />
      <img src={Logo} className="logo-img" alt="#" />

      <h1 className="form-header">Log in</h1>
      <GoogleLogin
        icon={false}
        clientId={config.GOOGLE_CLIENT_ID}
        disabled={loading}
        className='google-container'
        onSuccess={async (
          response: GoogleLoginResponse | GoogleLoginResponseOffline
        ) => {
          try {
            setLoading(true);
            //@ts-ignore
            const tokenId: string = response["tokenId"];
            const { token } = await http.googleLogin(tokenId);
            setToken(token);
            setLoading(false);
            history.replace("/plans");
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        }}
        onFailure={(error: any) => {
          console.error(error);
        }}
        cookiePolicy="single_host_origin"
      >
        <div >
          <img src={GoogleLogo} className="google-logo" alt="logo" />
          <text className='google-text'>Continue with Google</text>
        </div>

      </GoogleLogin>
      <div className='apple-button'>
        <AppleLogin
          designProp={
            {
              type: "continue",
              scale: 2
            }
          }
          clientId={config.APPLE_CLIENT_ID}
          redirectURI={config.APPLE_REDIRECT_URL}
        />
      </div>

      <FacebookLogin
        cssClass='facebook-button'
        appId={config.FACEBOOK_ID}
        fields="email"
        icon="fa-facebook"
        callback={async (
          response: ReactFacebookLoginInfo | ReactFacebookFailureResponse
        ) => {
          try {
            setLoading(true);
            const accessToken = (response as ReactFacebookLoginInfo)
              .accessToken;
            console.log(accessToken);
            if (!accessToken) {
              throw Error((response as ReactFacebookFailureResponse).status);
            } else {
              const token = await http.facebookLogin(accessToken);
              setToken(token);
              history.replace("/plans");
            }
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }}
      />
      <div className="separator"></div>
      <LoginForm />
    </div>
  );
};

const styles = css`
  .login-form {
    display: flex;
    flex-flow: column;
    justify-items: space-between;
    height: 100%;
    @media (min-width: 678px) {
      max-width: 480px !important;
      width: 100%;
      margin: auto;
    }
  }
  .google-container {
    display: flex;
    background-color: rgba(234, 67, 53, 1)!important;
    align-items: center;
    justify-content:center;
    color: white !important;
    border-radius: 6px!important;
    font-size: 21px!important;
    border: none!important;
    padding: 11px!important;
  }
  .google-logo{
    margin-right: 12px;
  }
  .google-text{
    font-weight: 600!important;
  }
  .apple-button {
    margin-top: 12px;
    display: flex;
    background-color: black!important;
    align-items: center;
    justify-content:center;
    border-radius: 6px!important;
  }

  .facebook-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
    background-color: rgba(24, 119, 242, 1) !important;
    color: white !important;
    text-align: center!important;
    border-radius: 6px!important;
    font-size: 19px!important;
    padding: 18px!important;
    font-weight: 600!important;
    border: none;
  }
  .fa{
    margin-right: 12px!important;
  }
  .logo-img {
    width: 100%;
    max-width: 500px;
    margin: 32px 0;
  }
  .separator {
    width: 100%;
    border-bottom: solid 1px rgba(207, 207, 209, 1);
    position: relative;
    margin: 30px 0px;
  }
  .separator::before {
    content: "or";
    position: absolute;
    left: 47%;
    top: -8px;
    color: rgba(101, 101, 102, 1);
    background-color: #fff;
    padding: 0px 10px;
}

`;
