/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from 'react';
import { Button } from '../common/Button';

export const ScrollToTopButton = () => {
  const [offsetFlag, setOffsetFlag] = useState(false)
  useEffect(() => {
    const handler = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setOffsetFlag(true)
      }
      else {
        setOffsetFlag(false)
      }
    }

    window.addEventListener('scroll', handler)
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <Button color="checkmark-green"
      css={css` visibility: ${offsetFlag
        ? 'visible' : 'hidden'};;`}
      text={'Back to top'}
      onClick={() => {
        setOffsetFlag(false)
        window.scrollTo(0, 0);
      }}
    />
  )
}