import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "../components/common/Button";
import { InputField } from "../forms/fields/InputField";
import Logo from "../assets/icons/greenhouse-logo.svg";
import { http } from "../api/http";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "../components/common/ErrorMessage";

const schema = yup.object().shape({
  password: yup.string().required().min(6, 'Password must be at least 6 characters'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match")
    .required(""),
});

export const ResetPasswordPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { token } = useParams<any>();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (data: { password: string }) => {
    setLoading(true);
    try {
      await http.resetPassword(token, data.password);
      setPasswordChanged(true);
    } catch (e) {
      setIsPasswordError(true);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form">
      <Global styles={styles} />
      {isPasswordError && <ErrorMessage
        title="Something went wrong"
        message="We could not reset your password" />}
      <img src={Logo} className="logo-img" alt="#" />
      {passwordChanged ? (
        <h1 className="form-header">Reset password successful.</h1>
      ) : (
        <>
          <h1 className="form-header">Reset password</h1>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="form-content"
            >
              <InputField
                name="password"
                placeholder="password"
                label="Password"
                type="password"
                errorMessage="Password must be at least 6 characters"
              />
              <InputField
                name="repeatPassword"
                placeholder="password"
                label="Repeat password"
                type="password"
                errorMessage="Passwords must match"
              />
              <div className="button-container">
                <Button
                  color="forrest-green"
                  text="Submit"
                  type="submit"
                  fullWidth
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

const styles = css`
  .login-form {
    display: flex;
    flex-flow: column;
    justify-items: space-between;
    height: 100%;
    @media (min-width: 678px) {
      max-width: 480px !important;
      width: 100%;
      margin: auto;
    }
  }
  .logo-img {
    width: 100%;
    max-width: 500px;
    margin: 32px 0;
  }
  .form-header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 32px;
    text-align: center;
  }
  .form-content {
    flex-grow: 1;
    flex-flow: column;
    position: relative;
    justify-content: space-between;
    padding-bottom: 81px;
  }
  .button-container {
    position: absolute;
    bottom: 24px;
    width: 100%;
  }
`;
