import { Global, css } from "@emotion/react";

import { observer } from "mobx-react";

interface ICreditCard {
  card: {
    brand: string;
    last4: string;
  };
}

export const CreditCard = observer((props: ICreditCard) => {
  const { card } = props;

  return (
    <>
      <Global styles={styles} />
      <div className="credit-card">
        <img alt="credit card" src={`/assets/icons/${card.brand}.png`} />
        <span>•••• •••• •••• {card.last4}</span>
      </div>
    </>
  );
});

const styles = css`
  .credit-card {
    display: flex;
    align-items: center;

    img {
      height: 30px;
      margin-right: 10px;
    }
  }
`;
