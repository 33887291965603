export const Colors = {
  primaryAction: '#00674F',
  blue: '#1F6DE2',
  skyBlue: '#44ABF6',
  goodHouseGreen: '#00B987',
  goodHouseDarkGreen: '#34B98D',
  goodHouseLightGreen: '#9CF8BF',
  lightGreenBackground: '#F1FCF2',
  goodHouseDeepGreen: '#0A3325',
  goodHouseHeading: '#3D3C3C',
  accentGreen: '#004737',
  checkmarkGreen: '#00B987',
  purple: '#1E2245',
  darkBlue: '#10395F',
  lightBlue: '#D3EEF4',
  grey: '#A1A2A3',
  borderGrey: '#CFCFD1',
  focusedGrey: '#EAF0F1',
  lightestGreen: '#D5F8DE',
  forestGreen: '#00674F',
  green: '#0CE0B6',
  grass: '#38F7A0',
  greyGreen: '#18323F',
  darkGrey: '#6E707B',
  darkText: '#3D3D3D',
  errorRed: '#F26050',
  placeholderGrey: '#B8B8B8',
  
};
