import { Global, css } from "@emotion/react";
import { useController, useFormContext } from "react-hook-form";

import { Colors } from "../../utils/Colors";
import RoundedExclamation from "../../assets/icons/rounded-exclamation.svg";

interface IFieldProps {
  name: string;
  placeholder: string;
  errorMessage?: string;
  type?: string;
  label?: string;
}

export const InputField = (props: IFieldProps) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { name, placeholder, errorMessage, type, label } = props;
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: "",
  });

  return (
    <div className="input-container">
      <Global styles={styles} />
      <label className="label-style">{label}</label>
      <input
        className={`input-field-style 
        ${invalid ? "error-state" : ""}`}
        {...field}
        type={type || "text"}
        placeholder={placeholder}
      />
      {invalid && (
        <span className="error-message">{errorMessage ?? error?.message}</span>
      )}
      <div className={`icon-placeholder ${invalid ? "show" : ""}`}>
        <img src={RoundedExclamation} width={20} height={20} alt="#" />
      </div>
    </div>
  );
};

const styles = css`
  .input-container {
    position: relative;
  }

  .input-field-style {
    border-radius: 4px;
    border-width: 0px;
    padding: 17px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: ${Colors.darkText};
    max-width: 480px;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    border: 1px solid ${Colors.borderGrey};
    background-color: transparent;

    &:focus {
      border: 1px solid ${Colors.forestGreen};
      outline: none;
    }

    &.error-state {
      border: 1px solid ${Colors.errorRed};
    }

    &::placeholder {
      color: ${Colors.placeholderGrey};
    }
  }

  .error-message {
    font-size: 10px;
    line-height: 14px;
    color: ${Colors.errorRed};
  }

  .label-style {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: block;
    color: ${Colors.accentGreen};
    margin: 12px 0;
  }

  .icon-placeholder {
    transition: opacity 0.1s ease-out;
    position: absolute;
    top: 19px;
    right: 15px;
    opacity: 0;
  }

  .show {
    opacity: 1;
  }

  .error-state {
    color: ${Colors.errorRed} !important;
  }
`;
