/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useParams } from "react-router";
import { useGetMyHome } from "../../../queries/home";
import { Picker } from "../../../shared/Picker";
import { Colors } from "../../../utils/Colors";


const titleStyle = css`
    font-size: 20px;
    font-weight: 700;
    color:${Colors.goodHouseHeading};
`
const houseTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`

export const SubscriptionSchedulePage = () => {
    const { homeId } = useParams<{ homeId: string }>();
    const { data: home } = useGetMyHome(homeId);

    return <div>
        <h3 css={titleStyle}>Schedule your subscription services</h3>
        <h3 css={houseTextStyle}>House: {home?.name}</h3>
        <Picker />

    </div>
};