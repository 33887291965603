import { Global, css } from '@emotion/react';
import Dropdown, { Option } from 'react-dropdown';
import { observer } from 'mobx-react';
import { Button } from './common/Button';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetMyHomes } from '../queries/homes';
import { selectAPlanStore } from '../stores/SelectAPlanStore';
import { Colors } from '../utils/Colors';

interface IPlanFeature {
  icon: string;
  name: string;
}

interface IPlanCard {
  id: string;
  planName: string;
  monthlyPrice: number | undefined;
  items: IPlanFeature[];
}

export const CurrentSubscription = observer((props: IPlanCard) => {
  const search = useLocation().search;
  const history = useHistory();
  const info = new URLSearchParams(search).get('info');
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);



  const {
    data: homes,
    isLoading: isLoadingHomes,
    isError: errorHomes
  } = useGetMyHomes();



  const setActiveHome = async (option: Option) => {
    const home = homes?.find(h => h.id.toString() === option.value)
    selectAPlanStore.home = home;
    setHasSubscription(home?.subscription_id != null);
  }
  useEffect(() => {
    if (homes !== undefined) {
      selectAPlanStore.home = homes[0];
    }

  }, [homes]);
  if (isLoadingHomes) {
    return <div>loading...</div>
  }
  if (errorHomes) {
    return <div>Error happened</div>
  }

  return (
    <div className="current-plan">
      <Global styles={styles} />
      <h1>Subscription</h1>
      {info === 'created' && (
        <div className="status-bar">
          <img src="/assets/icons/green-checkmark.svg" alt="checkmark" />
          <span>Your subscription plan has been successfully started.</span>
        </div>
      )}
      <h3>House</h3>
      <Dropdown
        className="select"
        placeholderClassName="light-text-description"
        controlClassName="select-control"
        options={homes!.map(h => ({ label: h.name, value: h.id.toString() }))}
        onChange={setActiveHome}
        value={selectAPlanStore.home?.id?.toString()}
        placeholder="Select a house" />
      {!hasSubscription && selectAPlanStore.home !== undefined && <> <h2>No active subscription</h2> </>}
      {hasSubscription && <> <h2>Subscription active</h2> </>}
      {!hasSubscription && selectAPlanStore.home !== undefined && <> < Button
        className="see-subscriptions"
        onClick={() => {
          history.push(`/home/${selectAPlanStore.home?.id?.toString()}/start-subscription`)
        }}
        text="Start subscription"
        color="forrest-green" /> </>}
    </div>
  );
});

const styles = css`
  .current-plan {
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;

    .select {
      margin-bottom: 10px;
    }

    .loader {
      align-self: center;
    }

    .select-control {
      border: 1px solid #CFCFD1;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .status-bar {
      display: flex;
      align-items: center;
      border: 1px solid #00b987;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 10px;

      img {
        padding-right: 10px;
      }

      span {
        font-weight: 500;
        font-size: 1rem;
        color: #00b987;
      }
    }

    .current-plan-payment {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .plan-info {
      color: #656566;
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 16px;
    }

    .see-subscriptions {
      position:absolute;
      bottom:0;
      left:10px;
      right:10px;
      margin-top: 16px;
      margin-bottom: 12px;
      border-radius: 8px;
    }

    .flex-grow {
      flex-grow: 1;
    }

    .logout-button {
      border-radius: 8px!important;
      height: 45px;
      margin-bottom: 48px;
    }
    .to-bottom {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }
    .plan-container{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .edit-plan{
      background-color: ${Colors.lightGreenBackground};
      border:1px solid ${Colors.goodHouseGreen};
      color: ${Colors.goodHouseGreen};
      padding: 8px 32px;
      font-size: 10px;
      padding: 24px, 48px;
      border-radius: 6px;
      font-weight: 700;
      line-height: 14px;
    }
  }
`;