/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHistory, useParams } from "react-router";
import rightGreyArrow from "../assets/icons/right-arrow-grey.svg";
import { Colors } from "../utils/Colors";
import back from '../assets/icons/close.svg';
import { useGetMySubscription } from "../queries/subscriptionPlans";
import { useGetMyHome } from "../queries/home";
import { SubscriptionPrice } from "../types/subscriptionPlan";
import { Button } from "../components/common/Button";
import { useEffect, useState } from "react";
import { http } from "../api/http";

const headerStyle = css`
    margin-top: 30px;
    display: flex;
    justify-content:space-between;
`
const backButtonStyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 33.44px;
    color: ${Colors.goodHouseHeading};
`
const subtitleStyle = css`
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
`
const houseTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`

const descriptionStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:#656566;
`

const planFrequencyStyle = css`
    border: 1px solid #CFCFD1;
    font-weight: 400;
    color:#656566;
    font-size:12px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    `
const activePlanFrequencyStyle = css`
    border: 1px solid  ${Colors.goodHouseGreen};
    font-weight: 700;
    color: ${Colors.goodHouseGreen};
    background-color:${Colors.lightGreenBackground};
    font-size:12px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  `
const seeServicesContainerStyle = css`
    margin: 0 6px 24px 16px;
    display: flex;
    justify-content: space-between;
`
const seeServicesStyle = css`
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    color:${Colors.grey};
`

export const EditFrequencyPage = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [selectedOption, setSelectedOption]: any = useState()
    const [loading, setLoading] = useState<boolean>(false);


    const {
        data: subscriptionData,
        isLoading: isLoadingSubscriptionData,
        isError: errorSubscriptionData
    } = useGetMySubscription(parseInt(id));
    const {
        data: home,
        isLoading: isLoadingHome,
        isError: errorHome
    } = useGetMyHome(id);

    useEffect(() => {
        const subscriptionPrice = subscriptionData.subscription.plan?.price.filter((s: SubscriptionPrice) =>
            s.stripePriceId === subscriptionData.subscription.stripePriceId
        )
        const stripePrice: SubscriptionPrice = subscriptionPrice[0];
        setSelectedOption(stripePrice)
    }, [subscriptionData])


    if (isLoadingSubscriptionData || isLoadingHome) {
        return <div>loading...</div>
    }
    if (errorSubscriptionData || errorHome) {
        return <div>Error happened</div>
    }


    return <div>
        <div css={headerStyle}>
            <span css={backButtonStyle}>Edit plan</span>
            <img src={back} alt="back" onClick={() => {
                history.goBack();
            }} />
        </div>

        <h3 css={subtitleStyle}>Edit service frequency</h3>
        <h2 css={descriptionStyle}>Service frequency determines how often a goodhouse technician will
            come by your house to perform your routine maintenance.</h2>
        <h3 css={houseTextStyle}>House: {home?.name}</h3>
        {subscriptionData.subscription.plan?.price.map((price: SubscriptionPrice) => {
            return (
                <div key={price.stripePriceId}>
                    <div
                        css={selectedOption === price ? activePlanFrequencyStyle : planFrequencyStyle}
                        onClick={() => {
                            setSelectedOption(price)
                        }}
                    >
                        <span>{price.billingCycle}</span>
                        <span>{`$${price.monthly}`}/month</span>
                    </div>
                    <div css={seeServicesContainerStyle} onClick={() => {
                        history.push(`frequency-services?billing-cycle=${price.billingCycle}`);

                    }}>
                        <span css={seeServicesStyle}>See services schedule</span>
                        <img src={rightGreyArrow} alt="next" />
                    </div>
                </div>
            );
        }
        )}

        <Button
            disabled={loading}
            loading={loading}
            text={'Save'}
            color="forrest-green"
            onClick={async () => {
                setLoading(true);
                await http.changePrice(subscriptionData.subscription.id, selectedOption.stripePriceId);
                setLoading(false);
                history.goBack();
            }}
            fullWidth={true} />

    </div>;
};