import { css, Global } from '@emotion/react';
import { observer } from 'mobx-react';


export const Divider = observer(() => {
    return (
      <>
        <Global styles={styles} />
        <div className="divider" />
      </>
    );
  }
);

const styles = css`
  .divider {
    height: 1px;
    background: #CFCFD1;
    margin: 40px 0;
  }
`;
