import { SubscriptionOverviewPage } from './start-subscription-page/subpages/SubscriptionOverviewPage';
import { SubscriptionFooter } from './start-subscription-page/SubscriptionFooter';
import { SubscriptionHeader } from './start-subscription-page/SubscriptionHeader';
import { useEffect, useState } from 'react';
import { SubscriptionSchedulePage } from './start-subscription-page/subpages/SubscriptionSchedulePage';
import { SubscriptionFrequencyPage } from './start-subscription-page/subpages/SubscriptionFrequencyPage';
import { SubscriptionPaymentPage } from './start-subscription-page/subpages/SubscriptionPaymentPage';
import { selectAPlanStore } from '../stores/SelectAPlanStore';
import { http } from '../api/http';
import { useHistory } from 'react-router';
import { useMyForm } from '../providers/MyFormProvider';



const components = [
  SubscriptionOverviewPage,
  SubscriptionFrequencyPage,
  SubscriptionSchedulePage,
  SubscriptionPaymentPage,
]
const pages = ['Overview', 'Frequency', 'Schedule', 'Payment'];


export const StartSubscriptionPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [isPaymentError, setIsPaymentError] = useState(false);
  const form = useMyForm();
  var [selectedPageIndex, setSelectedPageIndex] = useState(form.step);

  useEffect(() => {
    setSelectedPageIndex(form.step)
  }, [form.step])


  const goNext = () => {
    form.setStep(Math.min(pages.length - 1, selectedPageIndex + 1))
  }
  const goBack = () => {
    form.setStep(Math.max(0, selectedPageIndex - 1));
  }

  const confirmPlanPayment = async () => {

    const startDate = form.startDate;
    const endDate = form.endDate;
    const paymentMethodId = form.selectedCardId;
    try {
      setLoading(true);
      await http.buySubscription(
        paymentMethodId!,
        form.scheme!.id,
        startDate || undefined,
        endDate || undefined,
        form.isUrgent || false,
        form.isAfterNoon || false,


      );
      selectAPlanStore.reset();
      history.push("/?info=created");
    } catch (e) {
      setIsPaymentError(true);
    } finally {
      setLoading(false);
    }

  };
  const Step: any = components[selectedPageIndex]

  return (
    <div>
      <SubscriptionHeader
        pages={pages}
        selectedPage={selectedPageIndex}
      />
      <div style={{ minHeight: 'calc(100% - 75px - 78px)', marginBottom: '24px' }}>
        <Step />
      </div>
      <SubscriptionFooter

        selectedPageIndex={selectedPageIndex}
        onBack={goBack}
        onNext={goNext}
        pages={pages}
        loading={loading}
        onConfirm={confirmPlanPayment}
      />
    </div>

  );
};