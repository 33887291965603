import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { Button } from "../../components/common/Button";
import { InputField } from "../fields/InputField";
import { http } from "../../api/http";
import { useHistory } from "react-router";
import { useLocalState } from "use-enhanced-state/dist/types";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

// akshakdjhas
interface ILoginFormProps {
  setToken(token: any): any;
}
interface ILoginForm {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const LoginForm = () => {
  const history = useHistory();
  const [_, setToken] = useLocalState("token", undefined);
  const [loading, setLoading] = useState(false);

  const methods = useForm<ILoginForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);
    try {
      const loginData = await http.login(data);
      setToken(loginData.token);
      history.push("/");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Global styles={styles} />

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form-content"
        >
          <InputField
            name="email"
            placeholder="email"
            label="Email"
            type="email"
            errorMessage="Invalid email"
          />
          <InputField
            name="password"
            placeholder="password"
            label="password"
            type="Password"
            errorMessage="Invalid password"
          />
          <div className="button-container">
            <Button
              color="forrest-green"
              text="Submit"
              type="submit"
              fullWidth
              loading={loading}
              disabled={loading}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const styles = css`
  .form-header {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 32px;
  }
  .form-content {
    flex-grow: 1;
    flex-flow: column;
    position: relative;
    justify-content: space-between;
    padding-bottom: 81px;
  }
  .button-container {
    position: absolute;
    bottom: 24px;
    width: 100%;
  }
`;
