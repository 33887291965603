/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import inspection from "../../../assets/icons/inspection.svg";
import maintenance from "../../../assets/icons/maintenance.svg";
import consumables from "../../../assets/icons/consumables.svg";
import { Colors } from '../../../utils/Colors';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useGetMyHome } from '../../../queries/home';


const imageStyle = css`
    height: 20px;
    width: 20px;
`
const titleStyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 23.44px;
    color:${Colors.darkText};
`
const houseTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`
const subtitleStyle = css`
    font-size: 16px;
    font-weight: 600;
    line-height: 18.75px;
    color:${Colors.darkText};
`
const servceContainerStyle = css`
    display: flex;
`
const servceTitleStyle = css`
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-left: 10px;
    color:${Colors.darkText};
`
const bulletStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color:#656566;
`
const boldStyle = css`
    font-weight: 700;
`
const buttonContainerStyle = css`
    text-align: center;
`
const seeAllServicesButtonStyle = css`
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    color:${Colors.goodHouseGreen};
    background-color: transparent;
    border: none;
`
const availabilityStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`


export const SubscriptionOverviewPage = () => {
  const { homeId } = useParams<{ homeId: string }>();
  const { data: home } = useGetMyHome(homeId);
  const history = useHistory();
  function handleClick() {
    history.push(`/home/${homeId}/start-subscription/all-services`);

  };
  return <>
    <h1 css={titleStyle}>Start subscrtiption</h1>
    <h3 css={houseTextStyle}>House: {home?.name}</h3>
    <h2 css={subtitleStyle}>goodhouse Routine Maintenance Plan</h2>
    <div>
      <div css={servceContainerStyle}>
        <img src={inspection} css={imageStyle} alt="inspection" />
        <span css={servceTitleStyle}>Inspection</span>
      </div>
      <ul css={bulletStyle}>
        <li >Test carbon monoxide detectors</li>
        <li>Inspect electrical cords/outlets for wear</li>
        <li>Check fire extinguishers and locations</li>
        <li css={boldStyle}>etc.</li>
      </ul>
    </div>

    <div>
      <div css={servceContainerStyle}>
        <img src={maintenance} css={imageStyle} alt="inspection" />
        <span css={servceTitleStyle}>Hands on Maintenance</span>
      </div>
      <ul css={bulletStyle}>
        <li>Clean the garbage disposal</li>
        <li>Change the HVAC filters</li>
        <li>Replace the batteries in smoke and carbon monoxide detectors</li>
        <li css={boldStyle}>etc.</li>
      </ul>
    </div>

    <div>
      <div css={servceContainerStyle}>
        <img src={consumables} css={imageStyle} alt="inspection" />
        <span css={servceTitleStyle}>Included Consumables (free)</span>
      </div>
      <ul css={bulletStyle}>
        <li>HVAC filter</li>
        <li>Fridge filter</li>
        <li>Batteries</li>
        <li css={boldStyle}>etc.</li>
      </ul>
    </div>
    <div css={buttonContainerStyle}>
      <button onClick={handleClick} css={seeAllServicesButtonStyle}>See all services</button>
    </div>
    <h4 css={availabilityStyle}>Routine Maintenance plan is available in Monthly and Quarterly pricing options</h4>
  </>
};