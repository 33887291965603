
import React, { useContext } from "react";
import { PureComponent } from "react";
import { SubscriptionScheme } from "../types/subscriptionPlan";

export const useMyForm = () => {
  const form = useContext(FormContext);
  return form;
}

interface IFormContext {
  step: number;
  price?: number;
  scheme?: SubscriptionScheme | null,
  startDate: Date | null,
  endDate: Date | null,
  isUrgent: boolean,
  isAfterNoon: boolean | null,
  selectedCardId?: string,
  setMyFormPrice(price: number): any,
  setMyFormScheme(scheme: SubscriptionScheme): any,
  setMyFormIsUrgent(isUrgent: boolean): any,
  setMyFormIsAfterNoon(isAfterNoon: boolean): any,
  setMyFormStartEndTime(startDate: Date | undefined, endDate: Date | undefined): any,
  setMyFormSelectedCardId(selectedCardId: string): any,
  setStep(value: number): void
}

// @ts-ignore
const FormContext = React.createContext<IFormContext>(null);


export class MyFormProvider extends PureComponent {
  initialState = {
    price: undefined,
    scheme: null,
    startDate: null,
    endDate: null,
    isUrgent: true,
    isAfterNoon: true,
    selectedCardId: undefined,
    step: 0,

  }
  state = this.initialState;

  setMyFormPrice = (price: number) => {
    this.setState({ price });
  };
  setMyFormScheme = (scheme: SubscriptionScheme) => {
    this.setState({ scheme });
  };
  setStep = (value: number) => {
    this.setState({ step: value })
  }

  setStarEndTime = (startDate?: Date, endDate?: Date) => {
    this.setState({ startDate: startDate, endDate: endDate });
  };
  setIsUrgent = (isUrgent: boolean) => {
    this.setState({ isUrgent: isUrgent });
  };
  setIsAfterNoon = (isAfterNoon?: boolean) => {
    this.setState({ isAfterNoon: isAfterNoon });
  };
  setSelectedCardId = (selectedCardId: string) => {
    this.setState({ selectedCardId: selectedCardId });
  };

  render() {
    return (
      <FormContext.Provider
        value={{
          step: this.state.step,
          price: this.state.price,
          scheme: this.state.scheme,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          isUrgent: this.state.isUrgent,
          isAfterNoon: this.state.isAfterNoon,
          selectedCardId: this.state.selectedCardId,
          setMyFormPrice: this.setMyFormPrice,
          setMyFormScheme: this.setMyFormScheme,
          setMyFormStartEndTime: (startDate: Date | undefined, endDate: Date | undefined) => this.setStarEndTime(startDate, endDate),
          setMyFormIsUrgent: (isUrgent: boolean) => this.setIsUrgent(isUrgent),
          setMyFormIsAfterNoon: (isAfterNoon?: boolean) => this.setIsAfterNoon(isAfterNoon),
          setMyFormSelectedCardId: this.setSelectedCardId,
          setStep: this.setStep,
        }}
      >
        {this.props.children}
      </FormContext.Provider>
    );
  }
}
