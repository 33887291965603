import { Global, css } from "@emotion/react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AddACard } from "../components/Payment/AddACard";
import { AllServicesPlanPage } from "../pages/AllServicesPlanPage";
import { Colors } from "../utils/Colors";
import { CurrentSubscription } from "../components/CurrentSubscription";
import { EditBillingInfoPage } from "../pages/EditBillingInfoPage";
import { EditFrequencyPage } from "../pages/EditFrequencyPage";
import { EditSubscriptionPlanPage } from "../pages/EditSubscriptionPlanPage";
import { LoginPage } from "../pages/LoginPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { ServicesByFrequencyPage } from "../pages/ServicesByFrequencyPage";
import { StartSubscriptionPage } from "../pages/StartSubscriptionPage";
import { StoreTokenPage } from "../pages/StoreTokenPage";
import { useLocalState } from "use-enhanced-state";

export const AppRouter = (): JSX.Element => {
  return (
    <div className="main">
      <Global styles={styles} />
      <Switch>
        <Route exact path="/store" component={StoreTokenPage} />
        <PublicRoute exact path="/login" component={LoginPage} />
        <Route path="/" component={PrivateRouter} />
      </Switch>
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [token] = useLocalState("token", undefined);
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
const PublicRoute = ({ component: Component, ...rest }: any) => {
  const [token] = useLocalState("token", undefined);
  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export const PrivateRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path="/password/reset/:token"
        component={ResetPasswordPage}
      />
      <PrivateRoute
        exact
        path="*/home/:homeId/start-subscription"
        component={StartSubscriptionPage}
      />
      <PrivateRoute
        exact
        path="*/home/:homeId/start-subscription/all-services"
        component={AllServicesPlanPage}
      />
      <PrivateRoute
        exact
        path={"*/home/:homeId/start-subscription/frequency-services"}
        component={ServicesByFrequencyPage}
      />
      <PrivateRoute
        exact
        path={"*/home/:id/edit"}
        component={EditSubscriptionPlanPage}
      />
      <PrivateRoute
        exact
        path={"*/home/:id/edit-frequency"}
        component={EditFrequencyPage}
      />
      <PrivateRoute
        exact
        path={"*/home/:id/edit-billing"}
        component={EditBillingInfoPage}
      />
      <PrivateRoute exact path={"*/add-card"} component={AddACard} />
      <PrivateRoute exact path={"*/"} component={CurrentSubscription} />
      <Redirect to="*/" />
    </Switch>
  );
};

const styles = css`
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  h1 {
    font-size: 1.8rem; // 23.44
    color: ${Colors.goodHouseHeading};
  }

  h2 {
    font-size: 1.1rem; //18.75
    color: ${Colors.goodHouseHeading};
  }

  h3 {
    font-size: 1rem; // 16.41
    color: ${Colors.goodHouseHeading};
  }

  .light-text-description {
    font-size: 0.8rem;
    color: #a1a2a3;
  }

  .description {
    font-size: 0.8rem;
    color: #656566;
  }

  .main {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    height: 100%;
    padding: 0 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: space-between;
    }

    > div {
      max-width: 800px;
      width: 100%;
    }

    .action-buttons {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      div {
        flex: 1;

        button {
          width: 100%;
        }
      }
    }
  }
`;
