/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { selectAPlanStore } from '../../../stores/SelectAPlanStore';
import { ErrorMessage } from '../../../components/common/ErrorMessage';
import { Divider } from '../../../components/common/Divider';
import { CreditCard } from '../../../components/common/CreditCard';
import { Colors } from '../../../utils/Colors';
import { enumToString } from "../../../utils/utils";
import { http } from '../../../api/http';
import useAsyncEffect from 'use-async-effect';
import { useMyForm } from '../../../providers/MyFormProvider';

interface IChooseAPlanProps {
}

const titleStyle = css`
    font-size: 20px;
    font-weight: 700;
    color:${Colors.goodHouseHeading};
`
const subTitleStyle = css`
    font-size: 16px;
    font-weight: 700;
    color:${Colors.goodHouseHeading};
`
const houseTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`
const priceItemContainerStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const priceItemTitleStyle = css`
    font-size: 14px;
    font-weight: 500;
    color:${Colors.goodHouseHeading};
`
const priceItemSpanStyle = css`
    font-size: 12px;
    font-weight: 400;
    color:${Colors.grey};
`
const paymentTitleStyle = css`
    font-size: 16px;
    font-weight: 500;
    color:${Colors.goodHouseHeading};
`
const addCardStyle = css`
    margin-left: 16px;
    font-size: 14px;
    font-weight: 500;
    color:#656566;
`
const addCardContainerStyle = css`
    display: flex;
    font-size: 14px;
    font-weight: 500;
`
const creditCardStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const SubscriptionPaymentPage = observer((props: IChooseAPlanProps) => {
    const history = useHistory();
    const form = useMyForm();
    const [isPaymentError, setIsPaymentError] = useState(false);
    const [cards, setCards] = useState([]);
    useAsyncEffect(async () => setCards(await http.getMyCards()), []);



    useEffect(() => {
        if (cards.length > 1 && cards !== undefined) {
            const card: any = cards[0];
            form.setMyFormSelectedCardId(card.id)
        }
    }, [cards]);


    return (

        <div className="payment">
            {isPaymentError && <ErrorMessage
                title="We could not process your payment"
                message="Unfortunately, we could not collect payment on your purchase. Please review your billing information or check your balance." />
            }
            <h1 css={titleStyle}>Choose your payment option</h1>
            <div css={houseTextStyle}>House: {selectAPlanStore.home?.name}</div>
            <h3 css={subTitleStyle}>goodhouse plan</h3>

            <div css={priceItemContainerStyle}>
                <h3 css={priceItemTitleStyle}>goodhouse plan</h3>
                <h3>${form.price}<span css={priceItemSpanStyle}>/month</span></h3>
            </div>
            <span css={houseTextStyle}>Service frequency: {enumToString(form.scheme?.frequency ?? '')}</span>
            <Divider />
            <div css={priceItemContainerStyle}>
                <h3>Total</h3>
                <h3>${form.price}<span css={priceItemSpanStyle}>/month</span></h3>
            </div>
            <Divider />
            <h3 css={paymentTitleStyle}>Payment method</h3>

            {cards?.length && (
                <div >
                    {cards.map((card: any) => (
                        <div
                            css={creditCardStyle}
                            key={card.id}>
                            <CreditCard card={card.card} />
                            <input
                                checked={form.selectedCardId === card.id}
                                onChange={() => {
                                    form.setMyFormSelectedCardId(card.id);
                                }
                                }
                                name="card"
                                value={card.id}
                                type="radio"
                            />
                        </div>
                    ))}
                </div>
            )
            }
            <div css={addCardContainerStyle} onClick={() => history.push('/add-card')}>
                <img alt="add" src="/assets/icons/plus.svg" />
                <span css={addCardStyle} >Add card</span>
            </div>

        </div >
    );
});