/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHistory, useParams } from "react-router";
import { Colors } from "../utils/Colors";
import back from '../assets/icons/close.svg'
import { CreditCard } from "../components/common/CreditCard";
import { useEffect, useState } from "react";
import { http } from "../api/http";
import { useGetMyCards } from "../queries/payment";
import { useGetMySubscription } from "../queries/subscriptionPlans";


const headerStyle = css`
    margin-top: 30px;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
`
const backButtonStyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 33.44px;
    color: ${Colors.goodHouseHeading};
`
const subtitleStyle = css`
    font-size: 16px;
    font-weight: 700;
    color: ${Colors.darkText};
    margin-bottom: 40px;
`
const creditCardStyle = css`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const addCardStyle = css`
    margin-top: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
`
const addCardSpanStyle = css`
    line-height: 16px;
      color: #656566;
      margin: 0 12px;
`

export interface IStripePlanPrice {
    id: any;
    payment_method: any;
}


export const EditBillingInfoPage = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { data: cards = [] } = useGetMyCards();
    const [loadingCard, setLoadingCardId] = useState<string | undefined>(undefined);
    const [stripePlanPrice, setStripePricePlan] = useState<IStripePlanPrice>();
    const [selectedCard, setSelectedCard] = useState<string | undefined>(undefined);

    const {
        data: subscriptionData,
        isLoading: isLoadingSubscriptionData,
        isError: errorSubscriptionData
    } = useGetMySubscription(parseInt(id));

    useEffect(() => {
        setStripePricePlan(subscriptionData)
    }, [subscriptionData])

    if (isLoadingSubscriptionData) {
        return <div>loading...</div>
    }
    if (errorSubscriptionData) {
        return <div>Error happened</div>
    }

    const changeCreditCard = async (cardId: string) => {
        setLoadingCardId(cardId)
        await http.changeCreditCard(stripePlanPrice!.id, cardId)
        setSelectedCard(cardId);
        setLoadingCardId(undefined)
    };

    return (

        <div>
            <div css={headerStyle}>
                <span css={backButtonStyle}>Billing info</span>
                <img src={back} alt="back" onClick={() => {
                    history.goBack();
                }} />
            </div>
            <h3 css={subtitleStyle}>Credit or debit cards</h3>
            {cards?.length && (
                <div >
                    {cards.map((card: any) => (
                        <div
                            css={creditCardStyle}
                            key={card.id}>
                            <CreditCard card={card.card} />
                            {loadingCard !== card.id ?
                                <input
                                    checked={selectedCard !== undefined ? selectedCard === card.id : stripePlanPrice?.payment_method.id === card.id}
                                    onChange={() =>
                                        changeCreditCard(card.id)
                                    }
                                    name="card"
                                    value={card.id}
                                    type="radio"
                                />
                                :
                                <span key={card.id}>loading...</span>
                            }
                        </div>
                    ))}
                </div>
            )
            }

            <div css={addCardStyle} onClick={() => history.push('/add-card')}>
                <img alt="add" src="/assets/icons/plus.svg" />
                <span css={addCardSpanStyle}>Add card</span>
            </div>
        </div>

    );



};