/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHistory, useParams } from "react-router";
import back from '../assets/icons/close.svg';
import { useGetMySubscription } from '../queries/subscriptionPlans';
import { Subscription, SubscriptionPrice } from '../types/subscriptionPlan';
import { Colors } from "../utils/Colors";
import rightArrow from "../assets/icons/right-arrow-grey.svg";
import { useGetMyHome } from "../queries/home";
import ReactModal from "react-modal";
import { useState } from "react";
import { Button } from "../components/common/Button";
import { http } from "../api/http";
import { InputField } from "../forms/fields/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const headerStyle = css`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
`
const backButtonStyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 33.44px;
    color: ${Colors.goodHouseHeading};
`
const titleStyle = css`
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
`
const houseTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:#656566;

`
const planPriceStyle = css`
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color:#656566;
`
const planDescriptionStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`
const seePlanOverviewStyle = css`
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    color:${Colors.checkmarkGreen};
`
const sectionsContainerStyle = css`
    margin-top: 36px;
    position:relative;
`
const planSectionContainerStyle = css`
    display: flex;
    justify-content: space-between;
`
const planSectionStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const planSectionSubtitleStyle = css`
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color:${Colors.grey};
    margin-top: 8px;
`
const planSectionSpanStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`
const dividerStyle = css`
    height: 1px;
    background: #CFCFD1;
    margin: 24px 0px;
`
const cancelSubscription = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.checkmarkGreen};
`
const hideCancelSubscription = css`
    display: none;
`
const buttonPadding = css`
    margin-top:15px;
`



interface ISubscriptionCancellationForm {
    note: string;
}

const schema = yup.object().shape({
    note: yup.string().required(),
});


export interface IStripePlan {
    subscription: Subscription;
}
const renderSection = (title: string, price: string, onClick: VoidFunction) => {

    return (
        <div onClick={onClick}>
            <div css={planSectionContainerStyle}>
                <div css={planSectionStyle}>
                    <span css={planPriceStyle}>{title}</span>
                    {price !== '' &&
                        <span css={planSectionSubtitleStyle}><span css={planSectionSpanStyle}>{price}/</span>month</span>
                    }
                </div>
                <img src={rightArrow} alt="next" />

            </div>
            <div css={dividerStyle} />
        </div>
    )
}
export const EditSubscriptionPlanPage = () => {
    const methods = useForm<ISubscriptionCancellationForm>({
        resolver: yupResolver(schema),
        mode: "all",
    });
    const onSubmit = async (data: { note: string }) => {
        http.cancelSubscription({ subscriptionId: subscriptionData.subscription.id, cancellationNote: data.note });
        setShowSubscriptionCancellation(true);
        setShowModal(false)

    };
    const [showModal, setShowModal] = useState(false)
    const [showSubscriptionCancellation, setShowSubscriptionCancellation] = useState(false)
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const {
        data: subscriptionData,
        isLoading: isLoadingSubscriptionData,
        isError: errorSubscriptionData
    } = useGetMySubscription(parseInt(id));

    const { data: home } = useGetMyHome(id);

    if (isLoadingSubscriptionData) {
        return <div>loading...</div>
    }
    if (errorSubscriptionData) {
        return <div>Error happened</div>
    }

    const plan = subscriptionData.subscription.plan;
    var subscriptionPrice: any = plan?.price.filter((s: SubscriptionPrice) => s.stripePriceId === subscriptionData.subscription.stripePriceId,

    )
    const stripePrice: SubscriptionPrice = subscriptionPrice[0];


    return (
        <div>
            <div css={headerStyle}>
                <span css={backButtonStyle}>Edit plan</span>
                <img src={back} alt="back" onClick={() => {
                    history.goBack();
                }} />

            </div>
            <h3 css={titleStyle}>{plan?.name}</h3>
            <div>
                <h3 css={houseTextStyle}><span css={planPriceStyle}>${stripePrice.monthly}</span>/month • {home?.name}</h3>
            </div>
            <h3 css={planDescriptionStyle}>Editing your subscription plan preferences affects the pricing of your plan.</h3>
            <div onClick={() => { }}>
                <h3 css={seePlanOverviewStyle}>See plan overview</h3>
            </div>

            <div css={sectionsContainerStyle}>
                {renderSection(`Service frequency: ${stripePrice.billingCycle}`, `$${stripePrice.monthly}`, () => { history.push(`edit-frequency`); })}
                {renderSection('Additional services', '', () => { console.log('additional') })}
                {renderSection('Cancel subscription', '', () => { setShowModal(true) })}
                <h2 css={showSubscriptionCancellation ? cancelSubscription : hideCancelSubscription}>Your request for subscription cancellation is received.</h2>

                <ReactModal
                    isOpen={showModal}
                    contentLabel="Inline Styles Modal Example"
                    style={{
                        overlay: {
                            position: 'absolute',
                            backgroundColor: '#000000A6',

                        },
                        content: {
                            textAlign: 'center',
                            margin: 'auto',
                            color: 'lightsteelblue',
                            height: '350px',
                            display: 'flex',
                            flexDirection: 'column',
                            placeContent: 'space-evenly',


                        }
                    }}
                >
                    <h2 css={titleStyle}>Are you sure you want to cancel your subscription?</h2>



                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            className="form-content"
                        >
                            <InputField
                                name="note"
                                placeholder="Cancellation note"
                                label=""
                                type="text"
                                errorMessage="Please enter cancellation note"
                            />
                            <div className="button-container">
                                <Button
                                    css={buttonPadding}
                                    fullWidth={true}
                                    text={'Yes, cancel my subscription'}
                                    color="white-background"
                                />
                            </div>
                        </form>
                    </FormProvider>

                    <Button
                        fullWidth={true}
                        text={'Keep subscription'}
                        color="forrest-green"
                        onClick={() => { setShowModal(false) }}
                    />


                </ReactModal>
            </div >

        </div >

    );


};