import { Global, css } from "@emotion/react";
import { Colors } from "../../utils/Colors";
import Loader from "react-loader-spinner";

interface IButtonProps {
  onClick?(): void;

  fullWidth?: boolean;
  text: string;
  color:
  | "primary"
  | "primary-fade"
  | "blue"
  | "dark-blue"
  | "forrest-green"
  | "light-green"
  | "white-background"
  | "checkmark-green"
  | "checkmark-green-transparent"
  | "light-green-background";
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  containerClass?: string;
}

export const Button = (props: IButtonProps) => {
  const {
    onClick,
    text,
    color,
    type,
    className = "",
    disabled,
    fullWidth = false,
    loading = false,
    containerClass = ""
  } = props;
  return (
    <div className={containerClass}>
      <Global styles={styles} />
      <button
        disabled={disabled}
        className={`button-style ${color} ${className} ${fullWidth ? "full-width" : ""
          } ${loading ? "loading" : ""}`}
        onClick={onClick}
        type={type}
      >
        {loading ? (
          <Loader type="Oval" color="#FFF" width={32} height={32} />
        ) : (
          text
        )}
      </button>
    </div>
  );
};

const styles = css`
  .button-style {
    display: flex;
    font-weight: bold;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 44px;
    font-family: "Roboto";
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    border-width: 0px;
    border-radius: 3px;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &.primary {
      background: ${Colors.goodHouseGreen};
    }

    &.primary-fade {
      background: linear-gradient(
        270deg,
        ${Colors.goodHouseDarkGreen} 0%,
        ${Colors.goodHouseGreen} 100%
      );
    }

    &.blue {
      background: ${Colors.blue};
    }

    &.dark-blue {
      background: ${Colors.darkBlue};
    }

    &.forrest-green {
      background: ${Colors.forestGreen};
    }

    &.checkmark-green {
      background: ${Colors.checkmarkGreen};
    }

    &.checkmark-green-transparent {
      color: ${Colors.checkmarkGreen};
      background: transparent;
    }

    &.light-green {
      background: ${Colors.lightGreenBackground};
      border: 1px solid ${Colors.goodHouseDarkGreen};
      color: ${Colors.goodHouseDarkGreen};
      font-weight: bold;
      box-sizing: border-box;
    }

    &.white-background {
      background: white;
      border: 1px solid ${Colors.primaryAction};
      color: ${Colors.primaryAction};
      border-radius: 4px;
      font-size: 14px;
      box-sizing: border-box;
      width: 100%;
      font-weight: bold;
    }

    &.light-green-background {
      background: ${Colors.lightGreenBackground};
      border: 1px solid ${Colors.goodHouseGreen};
      color: ${Colors.goodHouseGreen};
      border-radius: 4px;
      font-size: 14px;
      box-sizing: border-box;
      width: 100%;
      font-weight: bold;
    }

    &.full-width {
      width: 100%;
    }

    :disabled,
    [disabled] {
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
    }

    &.loading {
      & :disabled,
      &[disabled] {
        background-color: ${Colors.forestGreen};
        color: #666666;
        cursor: not-allowed;
      }
    }
  }
`;
