import { Global, css } from "@emotion/react";

export const ServiceItem = (props: { serviceName: string, price?: number, quantity?: number }) => {
  const { serviceName, price, quantity } = props;
  return (
    <div className="service-item">
      <Global styles={styles} />
      <img src="/assets/icons/green-checkmark.svg" alt="checkmark" />
      <span className="quantityStyle">{quantity ? `${quantity} Qty,` : ''}</span>
      <span>{price ? `${serviceName} :` : serviceName} </span>
      {price != null &&
        <span className="priceStyle">${price}</span>
      }
    </div>
  );
};

const styles = css`
  .service-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    
    img {
      margin-right: 12px;
    }
  }

  .quantityStyle {
    margin-right: 6px;
  }
  .priceStyle {
    margin-left: 4px;
  }
`;

