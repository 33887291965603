import { Global, css } from "@emotion/react";
import { Colors } from '../../utils/Colors';
import RoundedExclamation from "../../assets/icons/rounded-exclamation.svg";

interface IErrorMessageProps {
  title?: string;
  message?: string;
}
export const ErrorMessage = ({title, message} :IErrorMessageProps) => {
  return <div className="error-message">
    <Global styles={styles} />
    <div>
      <img src={RoundedExclamation} width={18} height={18} alt='#' />
    </div>
    <div className="error-message__container">
      <p className="error-message__title">{title ?? ''}</p>
      <p className="error-message__message">{message ?? ''}</p>

    </div>
  </div>

}
const styles = css`
  .error-message {
    border: 1px solid ${Colors.errorRed};
    border-radius: 8px;
    margin: 24px 0;
    padding: 11px 15px;
    display: flex;
    font-size: 12px;
    line-height: 16.8px;
    &__container {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding-left: 15px;
    }
    &__title {
      color: ${Colors.errorRed};
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 4px;
    }
    &__message {
      color: ${Colors.grey};
      margin-top: 0;
      margin-bottom: 4px;

    }

  }



`;
