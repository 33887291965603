import ReactDOM from 'react-dom';
import 'react-dropdown/style.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from './shared/ErrorBoundary';
import { AppRouter } from './routers/AppRouter';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { MyFormProvider } from './providers/MyFormProvider';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

ReactDOM.render(
  <ErrorBoundary>
    <MyFormProvider>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <QueryClientProvider client={new QueryClient({
            defaultOptions: {
              queries: {
                refetchOnWindowFocus: false,
              },
            },
          })}>
            <AppRouter />
          </QueryClientProvider>
        </Elements>
      </BrowserRouter>
    </MyFormProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
