import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Global, css } from "@emotion/react";
import { Button } from "../common/Button";
import { http } from "../../api/http";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { useState } from "react";
import { ErrorMessage } from "../common/ErrorMessage";
import back from '../../assets/icons/close.svg';
import { Colors } from "../../utils/Colors";


export const AddACard = observer(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isCardError, setIsCardError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    if (!card) {
      return;
    }
    event.preventDefault();
    setLoading(true);
    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card,
      });
      await http.addCard({ paymentId: paymentMethod?.id || "" });
    } catch (e) {
      setIsCardError(true);
      return;
    } finally {
      setLoading(false);
    }
    history.goBack();
  };
  return (
    <div className="add-a-card">
      <Global styles={styles} />
      <div className="card-header">
        <span className="back-button-style">Add card</span>
        <img src={back} alt="back" onClick={() => {
          history.goBack();
        }} />


      </div>
      {isCardError && <ErrorMessage
        title="We could not add this card"
        message="Unfortunately, we could not add this card to your account. Please review your card information or check card validity." />}
      <form onSubmit={handleSubmit}>
        <CardElement
          className="card-input"
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        <div className="action-buttons">
          <Button
            disabled={loading}
            loading={loading}
            color="forrest-green"
            type="submit"
            text="Add card"
          />
        </div>
      </form>
    </div>
  );
});

const styles = css`
  .add-a-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    form {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

  .back-button-style{
    font-size: 20px;
    font-weight: 700;
    line-height: 33.44px;
    color: ${Colors.goodHouseHeading};
    }

    .card-header {
      margin-top: 30px;
    display: flex;
    justify-content: space-between;
    }

    .card-input {
      width: 100%;
      margin: 30px 0;
    }
  }
`;
