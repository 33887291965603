/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useHistory, useParams } from "react-router";
import { useGetMyHome } from "../../../queries/home";
import { Colors } from '../../../utils/Colors';
import rightGreyArrow from "../../../assets/icons/right-arrow-grey.svg";
import { selectAPlanStore } from "../../../stores/SelectAPlanStore";
import { useMyForm } from "../../../providers/MyFormProvider";
import { useGetHomeSubscriptionPlan } from "../../../queries/subscriptionPlans";
import { SubscriptionSchemeProduct } from "../../../types/subscriptionPlan";
import { enumToString } from "../../../utils/utils";
import { useEffect } from "react";

interface ISubscriptionFrequencyPageProps {
}

const titleStyle = css`
    font-size: 20px;
    font-weight: 700;
`
const houseTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:${Colors.grey};
`

const descriptionStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color:#656566;
`
const planFrequencyStyle = css`
    border: 1px solid #CFCFD1;
    font-weight: 400;
    color:#656566;
    font-size:12px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
`
const activePlanFrequencyStyle = css`
    border: 1px solid  ${Colors.goodHouseGreen};
    font-weight: 700;
    color: ${Colors.goodHouseGreen};
    background-color:${Colors.lightGreenBackground};
    font-size:12px;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  `
const seeServicesContainerStyle = css`
    margin: 0 6px 24px 16px;
    display: flex;
    justify-content: space-between;
`
const seeServicesStyle = css`
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    color:${Colors.grey};
`
export const SubscriptionFrequencyPage = (props: ISubscriptionFrequencyPageProps) => {
    const history = useHistory();
    const { homeId } = useParams<{ homeId: string }>();
    const { data: home } = useGetMyHome(homeId);
    const form = useMyForm();

    const {
        data: schemas,
        isLoading: isLoadingScheme,
        isError: errorScheme
    } = useGetHomeSubscriptionPlan(homeId);

    useEffect(() => {
        if (schemas !== undefined && schemas.length > 1) {
            form.setMyFormScheme(schemas![0])
            form.setMyFormPrice(schemas![0].products.reduce(
                (prev: any, curr: SubscriptionSchemeProduct) =>
                    prev + curr.product.price * curr.quantity,
                0
            ))
        }
    }, [schemas]);

    if (isLoadingScheme) {
        return <div>loading...</div>
    }
    if (errorScheme) {
        return <div>Error happened</div>
    }
    return (

        <div>
            <h3 css={titleStyle}>Select service frequency</h3>
            <h3 css={houseTextStyle}>House: {home?.name}</h3>
            <h2 css={descriptionStyle}>Service frequency determines how often a goodhouse technician will
                come by your house to perform your routine maintenance.
            </h2>
            {schemas?.length === 0 &&
                <span css={seeServicesStyle}>Currently you don't currently have any plan to pick...</span>
            }
            {schemas?.map(s => {
                const price = s.products.reduce(
                    (prev: any, curr: SubscriptionSchemeProduct) =>
                        prev + curr.product.price * curr.quantity,
                    0
                );
                return (
                    <div key={s.id}>
                        <div
                            css={form.scheme === s ? activePlanFrequencyStyle : planFrequencyStyle}
                            onClick={() => {
                                form.setMyFormScheme(s)
                                form.setMyFormPrice(price)
                            }}
                        >
                            <span>{enumToString(s.frequency)}</span>
                            <span>{`$${price}`}/month</span>
                        </div>
                        <div css={seeServicesContainerStyle} onClick={() => {
                            selectAPlanStore.selectedScheme = s;
                            history.push(`/home/${homeId}/start-subscription/frequency-services?billing-cycle=${s.frequency}`);

                        }}>
                            <span css={seeServicesStyle}>See services schedule</span>
                            <img src={rightGreyArrow} alt="next" />
                        </div>

                    </div>

                );
            })}

        </div >)
};