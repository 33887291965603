/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { observer } from 'mobx-react'
import { useHistory } from "react-router";
import { Colors } from "../utils/Colors";
import { selectAPlanStore } from "../stores/SelectAPlanStore";
import { ServiceItem } from "../components/SelectAPlan/ServiceItem";
import { capitalizeFirstLetter, enumToString, useQueryParams } from "../utils/utils";
import { ScrollToTopButton } from "../components/common/ScrollToTopButton";
import back from '../assets/icons/close.svg';
import { SubscriptionScheme, SubscriptionSchemeProduct } from "../types/subscriptionPlan";

const headerStyle = css`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
`
const backButtonStyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 33.44px;
    color: ${Colors.goodHouseHeading};
`

const subtitleStyle = css`
    font-size: 14px;
    font-weight: 500;
    color: ${Colors.darkText};
`

const subtitleSpanStyle = css`
    font-size: 12px;
    font-weight: 400;
    color: ${Colors.darkText};
`
const frequencyTitleStyle = css`
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    color: ${Colors.grey};
`
const goToTopStyle = css`
     position: fixed;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      @media (min-width: 768px) {
        display: none!important;
    } 
`

const renderItems = (scheme: SubscriptionScheme) => {
    return (
        <div>
            <h4 css={frequencyTitleStyle}>Products:</h4>
            {scheme.products.map((p: SubscriptionSchemeProduct) => {
                const price = p.quantity * p.product.price;
                return (
                    <div>
                        <ServiceItem key={p.id} serviceName={p.product.name} price={price} quantity={p.quantity} />
                    </div>
                )
            }
            )}

        </div>
    )
}
export const ServicesByFrequencyPage = observer(() => {
    const history = useHistory();
    const params = useQueryParams();
    const cycle = params.get("billing-cycle");
    const scheme = selectAPlanStore.selectedScheme!;

    const price = scheme.products.reduce(
        (prev: any, curr: SubscriptionSchemeProduct) =>
            prev + curr.product.price * curr.quantity,
        0
    );

    return (
        (
            <div>
                <div>
                    <div css={headerStyle}>
                        <span css={backButtonStyle}>Service frequency</span>
                        <img src={back} alt="back" onClick={() => {
                            history.goBack();
                        }} />
                    </div>
                    <h3 css={subtitleStyle}>{capitalizeFirstLetter(enumToString(cycle!))} plan schedule ${price}/<span css={subtitleSpanStyle}>month</span></h3>

                </div>
                {renderItems(scheme!)}

                <div css={goToTopStyle}>
                    <ScrollToTopButton />
                </div>
            </div>
        )
    );
});

