/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Colors } from '../../utils/Colors';


interface ISubscriptionHeader {
  pages: string[];
  selectedPage: number;
}

const subscriptionHeaderStyle = css`
   width: 100%;
   display: flex;
   justify-content: space-between;
   margin: 24px 0px 36px 0px;
   font-size: 12px; 
   font-weight: 400;

`
const pageBulletStyle = css`
   font-size: 22px;
   line-height: 0.7;
   color: ${Colors.borderGrey};
`

export const SubscriptionHeader = (props: ISubscriptionHeader) => {
  const {
    pages,
    selectedPage,
  } = props;



  return (

    <div>
      <div css={subscriptionHeaderStyle}>
        {pages.map((page, i) => (
          <>
            {i > 0 && i < pages.length && <span css={pageBulletStyle}>•</span>}
            <span css={css` color: ${i === selectedPage ?
              Colors.goodHouseGreen : Colors.grey};
              font-weight: ${i === selectedPage ?
                700 : 400};`}>{page}</span>
          </>
        ))}
      </div>
    </div>
  );
};

