/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Colors } from '../../utils/Colors';
import leftArrow from "../../assets/icons/left-arrow.svg";
import rightArrow from "../../assets/icons/right-arrow.svg";
import { Button } from '../../components/common/Button';

interface ISubscriptionFooter {
  selectedPageIndex: number;
  pages: String[];
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
  onConfirm: VoidFunction;

}

const subscriptionFooterStyle = css`
      display:flex;
      place-content: space-between;
      position:absolute;
      bottom:8px;
      left:14px;
      right:14px;
`
const buttonStyle = css`
      border: none;
      background-color: transparent;
      color: ${Colors.grey};
`
const textStyle = css`
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto" ;
`
const backStyle = css`
      margin-left:14px;
`
const nextStyle = css`
      color: ${Colors.goodHouseGreen};
      margin-right:14px;
`


export const SubscriptionFooter = (props: ISubscriptionFooter) => {
  const {
    selectedPageIndex,
    pages,
    loading,
    onNext,
    onBack,
    onConfirm
  } = props;


  return (
    <>
      {selectedPageIndex === 0 &&
        <Button
          className="see-subscriptions"
          onClick={() => {
            onNext()
          }}
          text="Next"
          color="forrest-green"
          fullWidth={true}
        />
      }
      {selectedPageIndex !== 0 && selectedPageIndex !== pages.length - 1 && <>
        <div css={subscriptionFooterStyle}>
          <button css={buttonStyle} onClick={onBack}><div>
            <img src={leftArrow} alt="back" />
            <span css={css(textStyle.styles, backStyle.styles)}>Back</span>
          </div></button>
          <button css={buttonStyle} onClick={onNext}><div>
            <span css={css(textStyle.styles, nextStyle.styles)}>Next</span>
            <img src={rightArrow} alt="next" />
          </div></button>

        </div>
      </>}

      {selectedPageIndex === pages.length - 1 &&
        <Button
          className="see-subscriptions"
          onClick={onConfirm}
          disabled={loading}
          loading={loading}
          text="Confirm"
          color="forrest-green"
          fullWidth={true}
        />
      }
    </>
  );
};


