import { useQuery } from 'react-query';
import { http } from '../api/http';
import { SubscriptionScheme, SubscriptionSchemeProduct } from '../types/subscriptionPlan';


export function useGetMySubscription(homeId: number) {
  return useQuery<any, any>(['subscription', homeId], () => http.getMyStripeSubscription(homeId));
}

export function useGetHomeSubscriptionPlan(homeId: string) {
  return useQuery<Array<SubscriptionScheme>, any>(['plan', homeId], () => http.getHomeSubscriptionPlan(homeId));
}

