import { http } from "../api/http";
import useAsyncEffect from "use-async-effect";
import { useHistory } from "react-router-dom";
import { useLocalState } from "use-enhanced-state";
import { useQueryParams } from "../utils/utils";

export const StoreTokenPage = () => {
  const query = useQueryParams();
  const history = useHistory();
  const [_, setToken] = useLocalState<string | undefined>("token", undefined);

  useAsyncEffect(async () => {
    const token = query.get("token");
    if (token != null) {
      setToken(token);
      try {
        await http.getMe();
        history.replace("/plans");
      } catch (error) {
        localStorage.clear();
        history.replace("/login");
      }
    }
  }, []);

  return <div />;
};
