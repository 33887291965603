import { ConsumableCategoryEnum } from "../types/enums";
import { Home } from "../types/home";
import { IUpdateConsumable } from "../types/consumables";
import { SubscriptionPlan } from "../types/subscriptionPlan";
import { SubscriptionScheme } from "../types/subscriptionPlan";
import axios from "axios";
import { config } from "../config";

export const axiosInstance = axios.create({
  baseURL: config.BACKEND_URL,
  withCredentials: false,
});

axiosInstance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
  }
  return config;
});

class HTTP {
  getMyStripeSubscription = async (homeId: number): Promise<any> => {
    const response = await axiosInstance.get(
      `/payment/my-subscription/${homeId}`
    );
    return response.data;
  };
  login = async (data: { email: string; password: string }) => {
    const response = await axiosInstance.post(`/auth/signin/email`, data);
    return response.data;
  };
  resetPassword = async (token: string, password: string) => {
    const response = await axiosInstance.post(
      `/auth/reset-password/${token}`,
      { password }
    );
    return response.data;
  };
  googleLogin = async (tokenId: string) => {
    const response = await axiosInstance.post("/auth/signin/google", {
      id_token: tokenId,
    });
    return response.data;
  };
  facebookLogin = async (accessToken: string) => {
    const response = await axiosInstance.post("/auth/signin/facebook", {
      access_token: accessToken,
    });
    return response.data;
  };
  getMe = async () => {
    const response = await axiosInstance.get("/auth/me");
    return response.data;
  };
  addCard = async (data: { paymentId: string }) => {
    const response = await axiosInstance.post("/payment/add-card", data);
    return response.data;
  };
  getMyCards = async () => {
    const response = await axiosInstance.get("/payment/my-cards");
    return response.data;
  };
  getConsumablesByCategory = async (category: ConsumableCategoryEnum) => {
    const response = await axiosInstance.get(
      `/consumable/products-by-category/${category}`
    );
    return response.data;
  };

  buySubscription = async (
    paymentMethodId: string,
    schemeId: number,
    scheduledStart?: Date,
    scheduledEnd?: Date,
    asSoonAsPossible?: boolean,
    isAfternoon?: boolean
  ) => {
    const response = await axiosInstance.post("/payment/create-subscription", {
      paymentMethodId,
      schemeId,
      isAfternoon,
      asSoonAsPossible,
      scheduledStart,
      scheduledEnd,
    });
    return response.data;
  };

  changePrice = async (subscriptionId: number, stripePriceId: string) => {
    const response = await axiosInstance.patch(
      `/payment/update-subscription-price/${subscriptionId}`,
      {
        stripePriceId: stripePriceId,
      }
    );
    return response.data;
  };
  changeCreditCard = async (
    subscriptionId: string,
    paymentMethodId: string
  ) => {
    const response = await axiosInstance.patch(`/payment/update-credit-card`, {
      stripeSubscriptionId: subscriptionId,
      paymentMethod: paymentMethodId,
    });
    return response.data;
  };
  getMyHomes = async (): Promise<Home[]> => {
    const response = await axiosInstance.get("/homes/my");
    return response.data;
  };
  getMyHome = async (homeId: String): Promise<Home> => {
    const response = await axiosInstance.get(`/homes/my/${homeId}/basic`);
    return response.data;
  };

  buyConsumables = async (data: IUpdateConsumable[]) => {
    const response = await axiosInstance.post(
      "/consumable/add-consumable-ordered",
      data
    );
    return response.data;
  };
  updateConsumables = async (data: IUpdateConsumable[]) => {
    const response = await axiosInstance.patch(
      "/consumable/update-consumable-ordered",
      data
    );
    return response.data;
  };

  getHomeConsumables = async (homeId: number) => {
    const response = await axiosInstance.get(`/consumable/my/${homeId}`);
    return response.data;
  };

  getHomeConsumablePrices = async (homeId: number) => {
    const response = await axiosInstance.get(`/consumable/my/prices/${homeId}`);
    return response.data;
  };

  cancelSubscription = async (data: {
    subscriptionId: String;
    cancellationNote: String;
  }) => {
    const response = await axiosInstance.post(
      `/payment/subscriptions/${data.subscriptionId}/cancel`,
      {
        clientNote: data.cancellationNote,
      }
    );
    return response.data;
  };

  getHomeSubscriptionPlan = async (
    homeId: string
  ): Promise<Array<SubscriptionScheme>> => {
    const response = await axiosInstance.get(
      `/subscription/scheme/home/${homeId}`
    );
    return response.data;
  };
}

export const http = new HTTP();
