/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Colors } from "../utils/Colors";
import { useMyForm } from "../providers/MyFormProvider";


const optionContainerStyle = css`
    border: 1px solid  ${Colors.borderGrey};
    border-radius: 4px;
    display: flex;
    padding: 14px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: #656566;
`
const activeOptionContainerStyle = css`
    border: 2px solid  ${Colors.checkmarkGreen};
    border-radius: 4px;
    display: flex;
    padding: 14px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    color: ${Colors.checkmarkGreen};
`
const checkboxStyle = css`
    border: 1px solid #656566;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 12px;
`
const activeCheckboxStyle = css`
    border: 1px solid ${Colors.checkmarkGreen};
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 12px;
`
const activeDotStyle = css`
    margin-left:4px;
    margin-top:4px;
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: ${Colors.checkmarkGreen};
`
const dotStyle = css`
    display: hidden;
`
const pickerContainerStyle = css`
    border: 1px solid  ${Colors.borderGrey};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 14px;
    color: #656566;
    line-height: 16.8px;
    font-size: 12px;
`
const activePickerContainerStyle = css`
    border: 2px solid  ${Colors.checkmarkGreen};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 14px;
    font-weight: 700;
    color: ${Colors.checkmarkGreen};;
    line-height: 16.8px;
    font-size: 12px;
`
const pickerTitleStyle = css`
    margin-top: 23px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
    color: ${Colors.accentGreen};
`
const buttonContainerStyle = css`
    display: flex;
    flex-direction: row;
`
const buttonStyle = css`
    width: 50%;
    border: 1px solid  ${Colors.borderGrey};
    padding: 11px 16px;
    color: ${Colors.accentGreen};
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
`
const activeButtonStyle = css`
    width: 50%;
    background-color: ${Colors.checkmarkGreen};
    padding: 11px 16px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 700;
`
const spacerStyle = css`
    width: 16px;
`

const style = css`
.react-datepicker__header{
    background-color:${Colors.checkmarkGreen};
}
.react-datepicker__current-month {
    color:white;
}
.react-datepicker__day{
   color: #656566;
}
.react-datepicker__day--keyboard-selected{
    background-color:${Colors.checkmarkGreen};
    color: white;
    
}
.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range){
    background-color:${Colors.checkmarkGreen};

}
.react-datepicker__day-name{
    color:white;
}
.DateInput_input::placeholder { color: red; }

.react-datepicker__day--in-range{
    background-color:${Colors.checkmarkGreen};
    color: white;
}
.react-datepicker__navigation-icon::before {
    border-color: white;
}
.react-datepicker__input-container{
    display: flex;
    input{
        border: 1px solid  ${Colors.borderGrey};
        padding: 10px 16px;
        width: 100%;
        border-radius: 4px;
        color: ${Colors.checkmarkGreen};
        font-weight: 700;
        font-size: 12px;
        background: url(../../calendar.png) no-repeat right center ;
        background-position: 95%;
    }}
`;

export const Picker = () => {
    const form = useMyForm();
    const onChange = (dates: [any, any]) => {
        const [start, end] = dates;
        form.setMyFormStartEndTime(start, end)
    }

    return (
        <div>
            <Global styles={style} />
            <div onClick={() => {
                form.setMyFormIsUrgent(true);
                form.setMyFormStartEndTime(undefined, undefined);

            }} css={form.isUrgent ? activeOptionContainerStyle : optionContainerStyle}>
                <span css={form.isUrgent ? activeDotStyle : dotStyle}></span>
                <span css={form.isUrgent ? activeCheckboxStyle : checkboxStyle}></span>
                <span >As soon as possible</span>
            </div>
            <div onClick={() => {
                form.setMyFormIsUrgent(false);

            }} css={!form.isUrgent ? activePickerContainerStyle : pickerContainerStyle}>
                <div>
                    <span css={!form.isUrgent ? activeDotStyle : dotStyle}></span>
                    <span css={!form.isUrgent ? activeCheckboxStyle : checkboxStyle}></span>
                    <span >Timeframe</span>
                </div>
                <span css={pickerTitleStyle}>Date interval</span>
                <DatePicker
                    placeholderText="select date"
                    minDate={new Date()}
                    selectsRange className="react-datepicker__input-container"
                    startDate={form.startDate}
                    endDate={form.endDate}
                    onChange={onChange} />
                <h2 css={pickerTitleStyle}>Time</h2>
                <div css={buttonContainerStyle}>
                    <div onClick={() => {
                        form.setMyFormIsAfterNoon(false);
                    }} css={!form.isAfterNoon ? activeButtonStyle : buttonStyle}>
                        <span>AM (8-12)</span>
                    </div>
                    <div css={spacerStyle}></div>
                    <div onClick={() => {
                        form.setMyFormIsAfterNoon(true);
                    }} css={form.isAfterNoon ? activeButtonStyle : buttonStyle}>
                        <span>PM (12-5)</span>
                    </div>
                </div>

            </div>
        </div>
    );
};



