import {  SubscriptionScheme } from '../types/subscriptionPlan';
import { makeAutoObservable } from 'mobx'
import { Home } from '../types/home';

class SelectAPlanStore {
  selectedScheme: SubscriptionScheme | undefined;
  selectedStep: number = 0;
  home: Home | undefined;
  cards: any[] = [];
  selectedCardId: any;

  constructor() {
    makeAutoObservable(this);
  }


  reset() {
    this.selectedScheme = undefined;
    this.selectedStep = 0;
    this.cards = [];
    this.selectedCardId = undefined;
  }
}

export const selectAPlanStore = new SelectAPlanStore()

