export const services = [
{
	label: 'Test carbon monoxide detectors',
	category: 'Inspection',
    monthly: {
        months: [],
        each: true
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
	label: 'Inspect electrical cords/outlets for wear',
	category: 'Inspection',
    monthly: {
        months: [],
        each: true
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
	label: 'Check basement for leaks after rains and freeze thaw cycle',
	category: 'Inspection',
    monthly: {
        months: [],
        each: true
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
	label: 'Check all lights and make sure they are grounded',
	category: 'Inspection',
    monthly: {
        months: [],
        each: true
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
	label: 'Inspect for mold',
	category: 'Inspection',
    monthly: {
        months: [],
        each: true
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Test smoke alarms',
	category: 'Inspection',
    monthly: {
        months: [],
        each: true
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Inspect the roof, gutters, and downspouts for damage after storms',
	category: 'Inspection',
    monthly: {
        months: [2,8],
        each: false
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Inspect caulking and weather stripping around doors, windows, and mechanicals',
	category: 'Inspection',
    monthly: {
        months: [3,7,11],
        each: false
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Change the HVAC filters',
	category: 'Hands on maintenance',
    monthly: {
        months: [1,4,7,10],
        each: false
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Check ground-fault circuits',
	category: 'Inspection',
    monthly: {
        months: [3,6,8,9,10,12],
        each: false
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Check fire extinguishers and locations',
	category: 'Inspection',
    monthly: {
        months: [1,6,7,12],
        each: false
    },
    quarterly: {
        months: [],
        each: true
    }
},
{
    label: 'Inspect tub and sink drains; unclog if necessary',
	category: 'Inspection',
    monthly: {
        months: [1,3,5,7,9,11],
        each: false
    },
    quarterly: {
        months: [1,6],
        each: false
    }
},
{
    label: 'Check that indoor and outdoor air vents are not blocked',
	category: 'Inspection',
    monthly: {
        months: [2,4,6,8,10,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Check dishwasher for leaks',
	category: 'Inspection',
    monthly: {
        months: [1,3,5,7,9,11],
        each: false
    },
    quarterly: {
        months: [1,6],
        each: false
    }
},
{
    label: 'Check around kitchen and bathroom cabinets and around toilets for leaks',
	category: 'Inspection',
    monthly: {
        months: [2,4,6,8,10,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Check the water softener',
	category: 'Inspection',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [],
        each: false
    }
},
{
    label: 'Clean the garbage disposal',
	category: 'Hands on Maintenance',
    monthly: {
        months: [2,5,8,11],
        each: false
    },
    quarterly: {
        months: [1,7],
        each: false
    }
},
{
    label: 'Replace the batteries in smoke and carbon monoxide detectors',
	category: 'Hands on Maintenance',
    monthly: {
        months: [1,4,7,10],
        each: false
    },
    quarterly: {
        months: [1,7],
        each: false
    }
},
{
    label: 'Clean the furnace filter to remove dust build-ups',
	category: 'Hands on Maintenance',
    monthly: {
        months: [2,5,8,11],
        each: false
    },
    quarterly: {
        months: [4],
        each: false
    }
},
{
    label: 'Vacuum lint from dryer vent',
	category: 'Hands on Maintenance',
    monthly: {
        months: [2,5,8,11],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Inspect chimney for damage',
	category: 'Hands on Maintenance',
    monthly: {
        months: [3,9],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Windows and doors lock properly',
	category: 'Hands on Maintenance',
    monthly: {
        months: [2,4,6,8,10,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Flush out hot water from the water heater to remove accumulated sediment',
	category: 'Hands on Maintenance',
    monthly: {
        months: [2,5,8,11],
        each: false
    },
    quarterly: {
        months: [1,7],
        each: false
    }
},
{
    label: 'Water softener refill',
	category: 'Hands on Maintenance',
    monthly: {
        months: [1,4,7,10],
        each: false
    },
    quarterly: {
        months: [1,7],
        each: false
    }
},
{
    label: 'Clean refrigerator and freezer condensor coils and empty and clean drip trays & check seals',
	category: 'Hands on Maintenance',
    monthly: {
        months: [3,6,9,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Plumbing fixture check',
	category: 'Hands on Maintenance',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Washing Machine Flush and Clean',
	category: 'Hands on Maintenance',
    monthly: {
        months: [3,6,9,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'WaterSoftener Machine Flush and Clean',
	category: 'Hands on Maintenance',
    monthly: {
        months: [3,6,9,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Dishwasher Machine Flush and Clean',
	category: 'Hands on Maintenance',
    monthly: {
        months: [3,6,9,12],
        each: false
    },
    quarterly: {
        months: [4,10],
        each: false
    }
},
{
    label: 'Water filters in fridge',
	category: 'Hands on Maintenance',
    monthly: {
        months: [1,4,7,10],
        each: false
    },
    quarterly: {
        months: [1,7],
        each: false
    }
},
{
    label: 'HVAC filter',
	category: 'Included Consumables (free)',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [],
        each: false
    }
},
{
    label: 'Fridge filter',
	category: 'Included Consumables (free)',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [],
        each: false
    }
},
{
    label: 'Water softener salt',
	category: 'Included Consumables (free)',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [],
        each: false
    }
},
{
    label: 'Appliance cleaner',
	category: 'Included Consumables (free)',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [],
        each: false
    }
},
{
    label: 'Batteries',
	category: 'Included Consumables (free)',
    monthly: {
        months: [],
        each: false
    },
    quarterly: {
        months: [],
        each: false
    }
},

]

export const inspectionServices = services.filter(services => services.category === 'Inspection')
export const handsOnMaintenanceServices = services.filter(services => services.category === 'Hands on Maintenance')
export const includedConsumablesServices = services.filter(services => services.category === 'Included Consumables (free)')
export const months: string[]= ['January', 'February', 'March', 'April','June','May','July','August','September','October','November','December'];
export const quarterly: string[]= ['January', 'April','July','October'];
export const servicesEachMonth = services.filter(services => services.monthly.each)
export const monthlyServices = Array(12).fill(1).map((_, i) => i + 1).map(month => services.filter(service => service.monthly.months.includes(month)));

export const servicesEachQuarter = services.filter(s => s.quarterly.each)
const servicesQuarterlyJanuary = services.filter(s => s.quarterly.months.includes(1))
const servicesQuarterlyApril = services.filter(s => s.quarterly.months.includes(4))
const servicesQuarterlyJuly = services.filter(s => s.quarterly.months.includes(7))
const servicesQuarterlyOctober = services.filter(s => s.quarterly.months.includes(10))
export const quarterlyServices = [servicesQuarterlyJanuary, servicesQuarterlyApril,servicesQuarterlyJuly,servicesQuarterlyOctober]